.visualizer {
  display: grid;
  place-items: center;
  margin-top: 100px;
}

.controlContainer {
  width: 100%;
  background-color: #34495e;
  padding: 0.3rem 5rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.title {
  color: #f9f9f9;
  font-size: 1.3rem;
  font-weight: 800;
}

.divider {
  width: 2px;
  height: 50px;
  background-color: #f9f9f9;
  margin: 0px 1.3rem;
}

.algorithms {
  color: #f9f9f9;
  font-size: 1.1rem;
  padding: 1rem 1.3rem;
  font-weight: 800;
  position: relative;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 60px;
  left: 0px;
  width: 250px;
  background-color: #34495e;
  padding: 1rem 0px;
  border-radius: 8px;
}

.dropdown > p {
  color: #f9f9f9;
  font-size: 1.1rem;
  padding: 0.7rem 1rem;
}

.dropdown > p:hover {
  background-color: #4ebd9c;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.activeBackground {
  background-color: #4ebd9c;
  border-radius: 6px;
}

.dropDownActive {
  display: block;
}

.dropDownInActive {
  display: none;
}

.btn {
  width: 15%;
  text-align: center;
  color: #f9f9f9;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 800;
  outline: none;
  border: none;
}

.btn:hover {
  background-color: #5dc9a8;
  transition: 0.2s all ease-in-out;
}

.row {
  display: flex;
  align-items: center;
}

.clean {
  color: #f9f9f9;
  font-size: 1.1rem;
  padding: 1rem 1.3rem;
  font-weight: 800;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}

.clean:hover {
  background-color: #5dc9a8;
  transition: 0.2s all ease-in-out;
  border-radius: 6px;
}

.infoContainer {
  width: 98%;
  position: fixed;
  bottom: 0;
  padding: 3.5rem 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start {
  width: 25px;
  margin-right: 6px;
}

.end {
  width: 25px;
  margin-right: 6px;
  margin-left: 40px;
}

.infoText {
  font-size: 1.1rem;
  font-weight: 800;
}

.wall {
  width: 25px;
  height: 25px;
  background-color: rgb(12, 53, 71);
  margin-right: 6px;
  margin-left: 40px;
}

.emptyNode {
  width: 25px;
  height: 25px;
  border: 2px solid lightblue;
  margin-right: 6px;
  margin-left: 40px;
}

.visitedNode {
  width: 25px;
  height: 25px;
  border: 1px solid lightblue;
  background-color: rgba(0, 190, 218, 0.75);
  margin-right: 6px;
  margin-left: 40px;
}

.path {
  width: 25px;
  height: 25px;
  border: 1px solid lightblue;
  background-color: rgb(255, 254, 106);
  margin-right: 6px;
  margin-left: 40px;
}
